/* default to light theme */
:root {
  --background-color: #bbb;
  --text-color: #282c34;
}

/* dark theme */
[data-theme="dark"] {
  --background-color: #282c34;
  --text-color: #bbb;
}

.App {
  text-align: center;
}

#Layer_1 {
  height: 200px;
  zoom:0.9;
  pointer-events: none;
}

path.latch {
  animation: App-logo-spin 5 5s cubic-bezier(1, 0.01, 1, 1);
  /* translate: 0px 51px;
  transform-origin: 30px 20px; */
  translate: 0px 52px;
  transform-origin: 115px 88px;
  fill: var(--text-color);
}

path.lock {
  fill: var(--text-color);
}

@media (prefers-reduced-motion: no-preference) {
  path.latch {
    animation: App-logo-spin 5 5s cubic-bezier(1, 0.01, 1, 1);
  }
}
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap");
.logo {
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-size: 3em;
}
body {
  background-color: var(--background-color);
  color: var(--text-color);
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2vmin;
  background-color: var(--background-color);
  color: var(--text-color);
}

.App-link {
  color: #61dafb;
}
span {
  font-size: 12px;
}

.email {
  margin-top: 30px;
  width: 250px;
  border: none;
  font-family: "Exo 2", sans-serif;
  padding: 0 10px;
  color: var(--background-color);
  font-size: 24px;;
  background-color: var(--text-color);
  height: 44px;
}
.email:active, .email:focus-visible {
  border: none;
  outline: 0;
}

.emailsubmit {
  display: inline-block;
  position: relative;
  margin-top: 30px;
  line-height: 23px;
  padding-top: 4px;
  font-size: x-large;
  border: none;
}

.emailsubmit:focus-visible {
  border: none;
  outline: 0;
}
.emailsubmit:active {
  background-color: red;
  border: none;
  outline: 0;
  color: white;
}
@keyframes App-logo-spin {
  0% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(-105deg);
  }
  60% {
    transform: rotate(-95deg);
  }
  70% {
    transform: rotate(-105deg);
  }

  75% {
    transform: rotate(-105deg);
  }
  100% {
    transform: rotate(1deg);
  }
}

.flex-container {
  display: flex;
}
